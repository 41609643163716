<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          fab
          bottom
          right
          dark
          v-bind="attrs"
          v-on="on"
          color="primary"
          @click="
            activeForm = 1;
            valid = false;
            openDialogRequest();
          "
          style="position: fixed; bottom: 25px; right: 145px"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Click to make pillar request.</span>
    </v-tooltip>
    <!-- <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          fab
          bottom
          right
          dark
          v-bind="attrs"
          v-on="on"
          color="orange"
          @click="
            activeForm = 2;
            submitted = false;
            valid = false;
            openDialogDues();
          "
          style="position: fixed; bottom: 25px; right: 85px"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Click to pay dues</span>
    </v-tooltip>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          fab
          bottom
          right
          dark
          v-bind="attrs"
          v-on="on"
          color="pink"
          @click="
            submitted = false;
            valid = false;
            openDialog();
          "
          style="position: fixed; bottom: 25px; right: 25px"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Click to record expense</span>
    </v-tooltip> -->

    <v-toolbar flat elevation="5">
      <v-badge color="warn" :content="filteredConnections.length" overlap>
        <v-toolbar-title>Members</v-toolbar-title>
      </v-badge>
      <v-btn icon @click="getMembers()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-btn tile raised @click="openDialogMember()">
        Add New Surveyor<v-icon>mdi-person-add</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-col class="mb-n6 col-lg-3 col-xs-6">
        <v-text-field
          v-model="search"
          placeholder="search"
          dense
          required
        ></v-text-field>
      </v-col>
      <v-btn icon @click="show_search = !show_search">
        <v-icon :color="searchStatusColor">mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row no-gutters>
      <v-col
        v-for="(item, i) in visiblePages"
        :key="i"
        col-lg-3
        col-md-4
        col-sm-6
        col-xs-4
      >
        <v-card class="my-1 mr-1" tile>
          <v-list-item>
            <!-- <v-list-item-avatar tile x-large>
              <img width="25" aspect-ratio="1" src="../assets/logo.png" />
            </v-list-item-avatar> -->
            <v-list-item-content>
              <v-list-item-title>
                <v-icon x-small>mdi-account</v-icon>
                {{ item.name }}
                <v-icon x-small>mdi-numeric</v-icon>Surcon: <span class="primary--text">{{ item.id }}</span>
                | <v-icon x-small>mdi-abc</v-icon>Suffix: <span class="brown--text">{{ item.pillarSuffix.trim() }}</span>                 
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon x-small>mdi-map-marker-radius</v-icon> 
                {{ item.phone + ", " + item.email }}
                <v-icon x-small>mdi-mdi-chart-areaspline-variant</v-icon>
                {{ item.company }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="grey" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="openDialogRequest(item)">
                    <v-icon class="pa-1">mdi-map-marker-path</v-icon>
                    <v-list-item-title>Pillar Request</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="openDialogDues(item)">
                    <v-icon class="pa-1">mdi-account-check</v-icon>
                    <v-list-item-title>Pay Annual Dues</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item @click="openDialogRequest(item)">
                    <v-icon class="pa-1">mdi-finance</v-icon>
                    <v-list-item-title>Record Expense/Income</v-list-item-title>
                  </v-list-item> -->
                  <!-- <v-list-item @click="removeConnection(i)">
                    <v-icon class="pa-1">mdi-file-account</v-icon>
                    <v-list-item-title>Generate Report</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="editRecord(i, item)">
                    <v-icon class="pa-1">mdi-pencil</v-icon>
                    <v-list-item-title>Edit Profile</v-list-item-title>
                  </v-list-item> -->
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-card>
      </v-col>
      <v-divider />
      <v-pagination
        v-model="list_paging.page"
        :length="Math.ceil(filteredConnections.length / list_paging.perPage)"
      >
      </v-pagination>
    </v-row>
    <v-dialog v-model="show_dialog_surcon" max-width="400px">
      <v-card width="auto" height="auto" class="ma-1 py-3">
        <div class="text-center" style="padding: 2px">
          <v-toolbar-title>ENTER SURCON NUMBER</v-toolbar-title>
        </div>
        <!-- <v-divider></v-divider>      -->
        <v-col cols="12">
          <div class="ma-auto" style="max-width: 300px">
            <v-otp-input
              v-model="surcon"
              :length="opt_size"
              @finish="setSurveyor()"
              type="number"
            ></v-otp-input>
            <v-overlay absolute :value="loading">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-overlay>
            <p v-show="alert.message" class="danger--text">
              {{ alert.message }}
            </p>
          </div>
        </v-col>
        <v-col cols="12">
          <v-btn block :disabled="!isActive" @click="setSurveyor()">OK</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
   
    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          Processing your request, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}</v-snackbar
    >
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="alert.alertDialog = false"
            >OK</v-btn
          >
          <v-btn
            v-else
            color="primary"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="show_dialog_request" max-width="900px">
      <v-card>
        
        <v-toolbar flat elevation="5">
      <v-toolbar-title>Add New Pillar Record</v-toolbar-title>
          
      <!-- <v-btn icon @click="getMembers()">
        <v-icon>mdi-refresh</v-icon>
      </v-btn> -->
      
      <v-spacer></v-spacer>
      <v-col class="mb-n6 col-lg-3 col-xs-6">
        <v-text-field
          v-model="search"
          placeholder="search"
          dense
          required
        ></v-text-field>
      </v-col>     
    </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-stepper v-model="request_page" alt-labels>
          <v-stepper-header>
            <v-stepper-step step="1" :complete="request_page > 1">
              Data Entry Page
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="request_page > 2">
              Preview Page
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form v-model="valid">
                <v-row class="mt-2">
                  <v-col col-md-5 col-xs-12>
                    <v-text-field
                      v-model="request.name"
                      prepend-inner-icon="mdi-certificate-outline"
                      dense disabled
                      readonly
                      label="Surveyor*"
                      hint="e.g. Surveyor's name"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                   <v-col col-md-2 col-xs-12>
                    <v-text-field disabled
                      v-model="request.surcon"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense
                      readonly
                      label="Surcon Number*"
                      hint="e.g. 1022"
                      :rules="[rules.required]"
                      persistent-hint
                    ></v-text-field>
                  </v-col>
                   <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="date"
                      dense
                      prepend-inner-icon="mdi-calendar-clock"
                      v-model="request.issuedDate"
                      label="Date Issued*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-2 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="request.quantity"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense                      
                      label="No. of pillars"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>                                  
                   <v-col col-md-2 col-xs-12>
                    <v-text-field
                      v-model="request.beaconPrefix"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense
                      label="Beacon Prefix*"
                      hint="e.g. The alphabet no. at the start of pillar no."
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="request.beginKey"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense                      
                      label="Start Value"
                      hint="enter the starting no. of Pillar*"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="request.endKey"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense
                      label="End Value"
                      hint="enter the ending value of Pillar*"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-2 col-xs-12>
                    <v-text-field
                      v-model="request.pillarSuffix"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense disabled
                      label="Surveyor Suffix"
                      hint="enter the character that ends the pillar number*"
                      :rules="[rules.required]"
                      persistent-hint                      
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-5 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="request.noOfPlans"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense  
                      @change="generatePlanList($event)"                   
                      label="No of Plans"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>              
                <v-card-title class="mx-n3">List of Survey PLan(s)</v-card-title>    
                <!-- <v-divider></v-divider> -->
                  <v-row v-for="(item, i) in request.plans" :key="i"> 
                    <v-col col-md-5 col-xs-12>
                    <v-select
                      v-model="item.lga"
                      :items="Lgas"
                      label="LGA"                      
                      :rules="[rules.required]"
                      dense
                    ></v-select>
                  </v-col>                                 
                   <v-col col-md-2 col-xs-12>
                    <v-text-field
                      v-model="item.planNumber"
                      dense 
                      label="Plan Number*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-5 col-xs-12>
                    <v-text-field
                      v-model="item.planTitle"
                      prepend-inner-icon="mdi-map-marker-radius"
                      dense 
                      :value="'title ' + i"                      
                      label="Plan Title"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-5 col-xs-12>
                    <v-text-field
                      type="Number"
                      v-model="item.size"
                      dense
                      label="Area"
                      hint="enter area in sqm*"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>                 
                </v-row>                
                <v-card-actions>
                  <v-btn
                    raised
                    color="warn"
                    tile
                    dark
                    @click="resetRequest()"
                    >Cancel</v-btn
                  >
                  <v-btn raised color="danger" tile dark @click="clearForm()"
                    >Clear</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    class="primary white--text"
                    raised
                    tile
                    @click="request_page = 2"
                    >Continue
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card elevation="0" class="text-center">
                <div class="row">
                  <div class="col-md-12 round-corner2">
                    <md-card-area>
                      <md-card-content id="printSection">
                        <div
                          style="background: url(./img/icons/logo.png) repeat"
                        >
                          <div style="opacity: 0.95; background-color: white">
                            <div class="invoice-box">
                              <img
                                height="200px"
                                width="200px"
                                style="
                                  opacity: 0.25;
                                  position: absolute;
                                  top: 45%;
                                  left: 40%;
                                "
                                src="../assets/logo.png"
                              />
                              <table
                                cellpadding="0"
                                cellspacing="0"
                                style="
                                  width: 100%;
                                  line-height: inherit;
                                  text-align: left;
                                "
                              >
                                <tr class="top">
                                  <td colspan="3">
                                    <table>
                                      <tr>
                                        <td class="title">
                                          <img
                                            src="../assets/logo.png"
                                            width="80px"
                                          />
                                        </td>
                                        <td></td>
                                        <td>
                                          <span
                                            style="
                                              font-size: 18px;
                                              font-weight: bold;
                                            "
                                            >Receipt No:
                                            {{ request.invoice_no }}</span
                                          >
                                          <br />
                                          Created:
                                          {{ new Date().toLocaleString() }}
                                          <br />
                                          Status: <b>{{ payment_status }}</b>
                                          <br />
                                          Quarter: <b>{{ request.quarter }}</b>
                                          <br />Plan Number(s):
                                          <b>{{ request.planno }}</b>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr class="information">
                                  <td colspan="3">
                                    <table>
                                      <tr>
                                        <td>
                                          Nigeria Institution of Surveyors
                                          <br />Block H, Plot B, Ikeja CBD,
                                          Alausa, Lagos State <br />Phone:
                                          (+234) 815 272 4484
                                        </td>
                                        <td></td>
                                        <td>
                                          <h3>Surv. {{ active_surveyor.name }}</h3>
                                          <br />
                                          {{ active_surveyor.phone }}
                                          <br />
                                          {{ active_surveyor.company }}
                                          <br />
                                          {{ active_surveyor.email }}
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <table>
                                      <tr class="heading">
                                         <td>Beacons</td>
                                        <td>Job Type</td>
                                        <td>Amount in words</td>
                                      </tr>
                                      <tr>
                                         <td>{{ request.pillars }}</td>
                                          <td>{{ request.job_type }}</td>
                                        <td>
                                          {{ amountInWords + "naira only" }}
                                        </td>
                                       
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <!-- <hr />   -->

                                <tr>
                                  <table>
                                    <tr class="heading">
                                      <td>Payment Break-down</td>
                                      <td>Unit Cost</td>
                                      <td>Total(N)</td>
                                    </tr>
                                    <tr class="item">
                                      <td>1.MDS</td>
                                      <td>
                                        {{ getNumberWithCommas(request.mds) }}
                                      </td>
                                      <td style="font-size: 15px">
                                        {{ getNumberWithCommas(request.mds) }}
                                      </td>
                                    </tr>

                                    <tr class="item">
                                      <td>2.SSCE Beacon Fees</td>
                                      <td>600</td>
                                      <td>
                                        {{ getNumberWithCommas(request.ssce) }}
                                      </td>
                                    </tr>

                                    <tr class="item">
                                      <td>3.Sect. Dev. Fund</td>
                                      <td>400</td>
                                      <td>
                                        {{ getNumberWithCommas(request.pdf) }}
                                      </td>
                                    </tr>
                                    <tr class="item">
                                      <td>4. APPSN Fees</td>
                                      <td>100</td>
                                      <td>
                                        {{ getNumberWithCommas(request.appsn) }}
                                      </td>
                                    </tr>

                                    <tr class="total" style="font-size: 16px">
                                      <td></td>
                                      <td class="strong">Total:</td>
                                      <td style="font-size: 20px">
                                        <b>{{
                                          getNumberWithCommas(request.total)
                                        }}</b>
                                      </td>
                                    </tr>
                                  </table>
                                </tr>
                                <hr />
                                <br />

                                <tr>
                                  <table>
                                    <tr
                                      class="total"
                                      style="font-size: 16px; text-align: right"
                                    >
                                      <hr />
                                      Signature & Date
                                      {{'Created by ' +
                                        userName
                                      }}
                                    </tr>
                                    <tr>
                                        <br />
                                      <div
                                        id="qrcode"
                                        style="margin:auto;padding:5px;
                                          border-width: 5px;
                                          width: 180px;
                                          height: 180px;
                                          border: solid, thick;
                                          border-color: black;                                   "
                                      ><div
                                        id="qrcode"></div></div>
                                    </tr>
                                  </table>
                                </tr>
                              </table>

                            </div>
                          </div>
                        </div>
                      </md-card-content>
                    </md-card-area>
                  </div>
                </div>
              </v-card>
              <v-divider class="mt-2"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile @click="request_page = 1"
                  >Previous</v-btn
                >
                <v-spacer></v-spacer>
                 <v-btn
                    raised
                    color="warn"
                    :disabled="submitted"
                    tile
                    dark
                    @click="resetRequest()"
                    >Cancel</v-btn>
                <v-btn
                  :disabled="submitted"
                  color="primary"
                  tile
                  raised
                  @click="submitRequest()"
                >
                  Submit
                </v-btn>
                <v-btn
                  :disabled="!submitted"
                  color="primary"
                  tile
                  raised
                  @click="printInvoice()"
                >
                  Print
                </v-btn>
                <v-btn
                  :disabled="!submitted"
                  color="primary lighten-2"
                  tile
                  raised
                  @click="resetRequest()"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="show_dialog_member" max-width="800px">
      <v-card>
        <div class="text-center" style="padding: 10px; magin-bottom: 10px">
          <v-toolbar-title>Register New Member</v-toolbar-title>
          <small>
            Supply all the required information below and click next</small
          >
        </div>
        <v-divider></v-divider>
        <v-stepper v-model="member_page" alt-labels>
          <v-stepper-header>
            <v-stepper-step step="1" :complete="member_page > 1">
              Register Member</v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="member_page > 2">
              Preview
            </v-stepper-step>
            <v-divider></v-divider>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form v-model="valid">
                <v-row class="mt-2">
                  <v-col col-md-9 col-xs-12>
                    <v-text-field
                      v-model="new_member.name"
                      prepend-inner-icon="mdi-certificate-outline"
                      dense
                      label="Fullname*"
                      hint="e.g. fullname without including Surv."
                      persistent-hint
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="number"
                      v-model="new_member.surcon"
                      prepend-inner-icon="mdi-123"
                      dense
                      label="Surcon Number*"
                      hint="if surcon number does not exist, use the member's phone no."
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="number"
                      v-model="new_member.phone"
                      prepend-inner-icon="mdi-call"
                      dense
                      label="Phone Number*"
                      hint="e.g. 080312345678"
                      :rules="[rules.required]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      v-model="new_member.email"
                      prepend-inner-icon="mdi-alternate_email"
                      dense
                      label="Email*"
                      hint="e.g. 1022"
                      :rules="[rules.required, rules.email]"
                      persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="date"
                      v-model="new_member.dob"
                      label="Date of Birth"
                      prepend-inner-icon="mdi-calender-clock"
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-select
                      v-model="new_member.cadre"
                      :items="['mnis', 'fnis', 'anis']"
                      label="Cadre*"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-map-marker-radius"
                      required
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col-md-2 col-xs-12>
                    <v-text-field
                      dense
                      prepend-inner-icon="mdi-abc"
                      v-model="new_member.plan_prefix"
                      label="Plan Prefix*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-4 col-xs-12>
                    <v-text-field
                      dense
                      prepend-inner-icon="mdi-store"
                      v-model="new_member.company"
                      label="Company*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      dense
                      prepend-inner-icon="mdi-home"
                      v-model="new_member.address"
                      label="Address*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-5"></v-divider>
                <v-card-actions>
                  <v-btn
                    raised
                    color="warn"
                    tile
                    dark
                    @click="show_dialog_member = false"
                    >Cancel</v-btn
                  >
                  <v-btn raised color="danger" tile dark @click="clearForm()"
                    >Clear</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="!valid"
                    class="primary white--text"
                    raised
                    tile
                    @click="member_page = 2"
                    >Continue
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card elevation="0" class="text-center">
                <v-row class="mt-2">
                  <v-col col-md-9 col-xs-12>
                    <v-text-field
                      v-model="new_member.name"
                      disabled
                      prepend-inner-icon="mdi-certificate-outline"
                      dense
                      label="Fullname*"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="number"
                      disabled
                      v-model="new_member.surcon"
                      prepend-inner-icon="mdi-123"
                      dense
                      label="Surcon Number*"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="number"
                      disabled
                      v-model="new_member.phone"
                      prepend-inner-icon="mdi-call"
                      dense
                      label="Phone Number*"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-row>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      v-model="new_member.email"
                      disabled
                      prepend-inner-icon="mdi-alternate_email"
                      dense
                      label="Email*"
                      :rules="[rules.required]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col col-md-3 col-xs-12>
                    <v-text-field
                      type="date"
                      disabled
                      v-model="new_member.dob"
                      label="Date of Birth"
                      prepend-inner-icon="mdi-calender-clock"
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-select
                      v-model="new_member.cadre"
                      :items="['mnis', 'fnis', 'anis']"
                      label="Cadre*"
                      :rules="[rules.required]"
                      prepend-inner-icon="mdi-map-marker-radius"
                      required
                      disabled
                      dense
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col-md-2 col-xs-12>
                    <v-text-field
                      dense
                      disabled
                      prepend-inner-icon="mdi-abc"
                      v-model="new_member.plan_prefix"
                      label="Plan Prefix*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-4 col-xs-12>
                    <v-text-field
                      dense
                      disabled
                      prepend-inner-icon="mdi-store"
                      v-model="new_member.company"
                      label="Company*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col col-md-6 col-xs-12>
                    <v-text-field
                      dense
                      disabled
                      prepend-inner-icon="mdi-home"
                      v-model="new_member.address"
                      label="Address*"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-divider class="my-3"></v-divider>
              </v-card>
              <v-divider class="mt-2"></v-divider>
              <v-card-actions>
                <v-btn raised color="warn" tile @click="member_page = 1"
                  >Previous</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="submitted"
                  color="primary"
                  tile
                  raised
                  @click="submitMember()"
                >
                  Submit
                </v-btn>
                <v-btn
                  :disabled="!submitted"
                  color="primary"
                  tile
                  raised
                  @click="show_dialog_member = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Api from "../app.settings.network";
import router from "../router";
import AppSettings from "../app.settings";
import VueHtmlToPaper from "vue-html-to-paper";
import Vue from "vue";
// const options = {
//   name: "_blank",
//   specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
//   styles: ["css/print.css"],
//   //"timeout": 1000,
//   autoClose: true,
//   windowTitle: "Pillar Request",
// };
// Vue.use(VueHtmlToPaper, options);
const apiUrl = AppSettings.API_ENDPOINT;
export default {
  data: function () {
    return {
      request : {
            surcon :  '',
            surveyor :  '',
            issuedDate :  '',
            quantity :  '',
            beaconPrefix :  '',
            beginKey :  '',
            endKey :  '',
            surveyorSurfix :  '',
            planNumberPrefix : '',
            noOfPlans:0,
            plans:[]
        },
      member_page: 1,
      new_member: {
        name: "",
        phone: "",
        surcon: "",
        email: "",
        state: "",
        cadre: "",
        plan_prefix: "",
        dob: "",
        address: "",
        company: "",
      },
      Lgas:["AGEGE","ALIMOSHO","AJEROMI-IFELODUN","AMUWO-ODOFIN","APAPA",
      "BADAGRY","EPE","ETI-OSA","IBEJU","IFAKO-IJAIYE","IKEJA","IKORODU",
      "KOSOFE","LAGOS ISLAND","LAGOS MAILAND","MUSHIN","OJO","OSHODI-ISOLO",
      "SOMOLU","SURULERE"],
      emptyMember: {
        name: "",
        phone: "",
        surcon: "",
        email: "",
        state: "",
        cadre: "",
        plan_prefix: "",
        dob: "",
        address: "",
        company: "",
      },
      payment_status: "Paid",
      list_paging: {
        page: 1,
        perPage: 14,
        pages: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      summary: {
        account_type: "",
        account_category: "",
        account_sub_category: "",
      },
      account_id: "",
      account_category_id: "",
      suffix: "/2022/LA",
      prefix: "/2022/LA",
      planno: "",
      numberWithCommas: 0,
      amountInWords: "",
      amountInWordsAccount: "",
      amountInWordsDues: "",
      activeForm: 0,
      opt_size: 4,
      surcon: "",
      loading: false,
      submitted: false,
      show_dialog_member: false,
      show_dialog_dues: false,
      show_dialog_request: false,
      show_dialog_: false,
      show_dialog_surcon: false,
      dues_page: 1,
      request_page: 1,
      page: 1,
      valid: false,
      hide: false,
      account_email: "",
      acceptTerms: false,
      profile_id: "",
      qrSecurity: "",
      qrCodePath: "",
      members: [],
      active_surveyor: {
        name: "Damilola Kolawole",
        surcon: 2849,
        cadre: null
      },
      request1: {
        job_type: "",
        surcon: 0,
        planno: "",
        mds: null,
        appsn: 0,
        ssce: 0,
        pdf: 0,
        pillars: "",
        request_date: "",
        total: "",
      },
      emptyRequest: {
        job_type: "",
        surcon: 0,
        planno: "",
        mds: null,
        appsn: 0,
        ssce: 0,
        pdf: 0,
        quarter:0,
        pillars: "",
        request_date: "",
        total: "",
      },
      account: {
        account_id: "",
        amount: "",
        payment_method: "",
        purpose: "",
        payment_date: "",
        paid_or_collected_by: "",
      },
      account_empty: {
        account_id: "",
        amount: "",
        payment_method: "",
        purpose: "",
        payment_date: "",
        paid_or_collected_by: "",
      },
      account_category: [],
      account_category_sub: [],
      account_type: [],
      dues: {
        surcon: 0,
        year: 2022,
        amount: 7500,
        payment_date: null,
        mode_of_payment: "transfer",
      },
      document: {
        email: "",
        title: "",
        location: "",
        lga: "",
        state: "",
        size: null,
        planNumber: "",
        planDate: null,
        station: "",
        easting: null,
        northing: null,
        planStatus: "Draft",
      },
      emptyDocument: {
        email: "",
        title: "",
        location: "",
        lga: "",
        state: "",
        size: null,
        planNumber: "",
        planDate: null,
        station: "",
        easting: null,
        northing: null,
        planStatus: "Draft",
      },
      Profile: {},
      file: { name: "" },
      loaded: false,
      search: "",
      searchStatusColor: "primary",
      show_search: false,
      states: ["Lagos", "Ogun", "Oyo", "Osun", "Ondo", "Ekiti", "Kwara"],
      emptyConnection: {
        server: "",
        userId: "",
        password: "",
        database: "",
        dbType: "",
        port: "",
        description: "",
        status: "",
        time: null,
        db: { noOfTables: 0, noOfViews: 0 },
      },
      vendors: ["mssql", "mysql", "pgsql", "oracle"],
      dbType: [
        { text: "mssql", port: 1433 },
        { text: "mysql", port: 3306 },
        { text: "pgsql", port: 5432 },
        { text: "oracle", port: 1521 },
      ],
      cadre: [
        { name: "fellow", value: 7500 },
        { name: "member", value: 5000 },
        { name: "associate", value: 2500 },
      ],
      showDialog: false,
      showProgress: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v.length = 4 || "Min 4 characters"),
        emailMatch: (v) =>
          (v.includes("@") && v.includes(".")) || "The email is not valid",
        passwordStrength: (v) =>
          (/\d/.test(v) &&
            /[a-z]/.test(v) &&
            /[A-Z]/.test(v) &&
            /\W/.test(v)) ||
          "password must be at least 1 Uppercase, 1 Lowercase, 1 number and 1 Special character",
      },
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "NIS",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
    };
  },
  filters: {
    getIcon: function (value) {
      return "http://localhost:8080/public/img/icons/" + value + ".png";
    },
    getIconColor(value) {
      if (value == "done") {
        return "success";
      } else {
        return "error";
      }
    },
  },
  computed: {
    isActive() {
      return (
        this.surcon.length > this.opt_size - 1, (this.alert.message = undefined)
      );
    },
    userName: function () {
      return this.$store.getters.getUserName;
    },
    filteredConnections() {
      if (this.members) {
        if (this.search.trim().length > 2) {
          const search = this.search.toLowerCase();
          return this.members
            .filter(
              (item) =>
                item.name.toLowerCase().includes(search) ||
                item.surcon == search ||
                item.phone == search
            )
            .sort((a, b) => a.name - b.name);
        }
        return this.members.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [];
      }
    },
    filteredCategory() {
      if (this.account_type) {
        if (this.account_id != "") {
          const account_id = this.account_id;
          return this.account_category
            .filter((item) => item.account_id == account_id)
            .sort();
        }
        return [];
        //return this.account_category.sort();
      } else {
        return [];
      }
    },
    filteredSubCategory() {
      if (this.filteredCategory != []) {
        // alert(this.account_selected_category)
        if (this.account_category_id != "") {
          const id = this.account_category_id;
          return this.account_category_sub
            .filter((item) => item.category_id == id)
            .sort();
        }
        return [];
        //return this.account_category_sub.sort();
      } else {
        return [];
      }
    },
    visiblePages() {
      return this.filteredConnections.slice(
        (this.list_paging.page - 1) * this.list_paging.perPage,
        this.list_paging.page * this.list_paging.perPage
      );
    },
  },
  methods: {
    submitRequest: function () {
      const vm = this;
      this.showProgress = true;
      const planno = this.planno;
      const invoice_no = new Date().getTime();
      this.request.planno = planno + this.suffix;
      this.request.invoice_no = this.request.surcon + "-" + invoice_no;
      Api()
        .post("api/backlog", this.request)
        .then((response) => {
          this.submitted = true;
          (this.request.id = response.data.id),
          (this.alert.text = response.data.message);
          this.showProgress = false;
          //this.alert.alertDialog = true;
          this.alert.snackbar = true;
          this.alert.bgcolor = "primary";
          this.page = 2;

          var qrData = "https://nisserver:5001/?receipt_no=" + this.request.invoice_no;
          var qrcode = new QRCode(document.getElementById("qrcode"), {
            width: 160,
            height: 160,
            colorDark: "primary",
            colorLight: "secondary",
            correctLevel: QRCode.CorrectLevel.H,
          });
          qrcode.makeCode(qrData);
        })
        .catch(function (ex) {
          return (
            (vm.submitted = false),
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    generatePlanList(val){
      //alert(val)
      //this.request.plans = [];
      var p = [];
      for(let i = 0; i < val; i++ ){
        let year = (new Date(this.request.issuedDate)).getFullYear();
        let planNo = this.request.surcon + "/" + i + "/" + year + "/LA"
        let plan = {};
        //plan.planNumberPrefix = "";
        plan.planNumber = planNo;
        plan.planTitle= "title" + i+1;
        plan.area = 0;
        plan.lga = "";
        p.push(plan)
      }      
      return this.request.plans = p;
    },
    submitMember: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .post("api/nis/members", this.new_member)
        .then((response) => {
          this.submitted = true;
          this.members.unshift(this.new_member);
          this.alert.text = response.data.message;
          this.showProgress = false;
          this.alert.snackbar = true;
          this.alert.bgcolor = "primary";
          this.page = 1;
          return (this.new_member = this.emptyMember);
        })
        .catch(function (ex) {
          return (
            (vm.submitted = false),
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    resetRequest(){
      this.request = {};
      this.show_dialog_request = false;      
      document.getElementById("qrcode").innerHTML = ""
    },
    calQuarter(){
      const month = new Date(this.request.request_date).getMonth() + 1;
      this.request.quarter = Math.ceil(month / 3);
    },
    getDuesAmount(value) {
      this.dues.amount = value;
    },
    getSummary() {
      const account_type = this.account_type.filter(
        (item) => item.id == this.account_id
      )[0].account;
      const account_category = this.account_category.filter(
        (item) => item.id == this.account_category_id
      )[0].category;
      const account_sub_category = this.account_category_sub.filter(
        (item) => item.id == this.account.account_id
      )[0].sub_category;
      let summary = {
        account_type,
        account_category,
        account_sub_category,
      };
      alert(account_sub_category);
      return (this.summary = summary);
    },
    getAccountType: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/nis/account")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          return (this.account_type = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    getAccountCategory: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/nis/account_category")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          return (this.account_category = response.data.sort());
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    getAccountSubCategory: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/nis/account_category_sub")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          return (this.account_category_sub = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    getAmountInWords(val) {
      //val is the value of @input = getAmountInWords($event)
      let words = numToWords(Number(val));
      let numberWithCommas = val
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        (this.amountInWordsAccount = words),
        (this.numberWithCommas = numberWithCommas)
      );
    },
    numToWords(val){
      if(val != null){
       let words = numToWords(Number(val));
       return words
    }},
    getNumberWithCommas(x) {
      if (x != null) {
        //alert(x)
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".00";
      }
    },
    calTotal() {
      const total =
        Number(this.request.mds) +
        Number(this.request.ssce) +
        Number(this.request.appsn) +
        Number(this.request.pdf);
      let words = numToWords(total);
      return (this.request.total = total), (this.amountInWords = words);
    },
    computeCharges() {
      const n = this.request.pillars;
      this.request.appsn = n * 100;
      this.request.ssce = n * 600;
      this.request.pdf = n * 400;

      const total =
        Number(this.request.mds) +
        Number(this.request.ssce) +
        Number(this.request.appsn) +
        Number(this.request.pdf);
      let words = numToWords(total);
      return (this.request.total = total), (this.amountInWords = words);
    },
    deleteRecord(index, item) {
      const vm = this;
      Api()
        .delete("api/nis/members/surcon" + item.surcon)
        .then((response) => {
          this.members.splice(index, 1);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = response.data)
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    openDialogMember() {
      return (
        (this.show_dialog_member = true),
        (this.page = 1),
        (this.new_member = this.emptyMember),
        (this.submitted = false)
      );
    },
    openDialog() {
      return (
        (this.showDialog = true),
        (this.page = 1),
        (this.document = this.emptyDocument)
      );
    },
    printInvoice() {
      const options = {
        name: "_blank",
        specs: ["titlebar=yes", "scrollbars=yes"],
        styles: ["css/print.css"],
        //"timeout": 1000,
        autoClose: true,
        windowTitle: this.active_surveyor.name,
      };
      Vue.use(VueHtmlToPaper, options);

      this.$htmlToPaper("printSection");
    },
    printInvoice2() {
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        styles: ["css/print.css"],
        //"timeout": 1000,
        autoClose: true,
        windowTitle: this.active_surveyor.name,
      };
      Vue.use(VueHtmlToPaper, options);

      this.$htmlToPaper("printSection2");
    },
    setSurveyor() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        const surcon = parseInt(this.surcon);
        let surveyor = this.members.filter((x) => {
          return x.surcon == surcon;
        })[0];
        //alert(JSON.stringify(surveyor))
        if (surveyor) {
          console.log(surveyor)
          this.active_surveyor.name = surveyor.name;
          this.active_surveyor.surcon = surveyor.surcon;
          this.active_surveyor.cadre = surveyor.cadre
          this.active_surveyor.company = surveyor.company;
          this.active_surveyor.phone = surveyor.phone
          this.active_surveyor.address = surveyor.address
          this.active_surveyor.pillarSuffix = surveyor.pillarSuffix.trim()
          this.show_dialog_surcon = false;
          if (this.activeForm == 1) {
            return (
              (this.request.surcon = surcon),
              (this.show_dialog_request = true),
              (this.request_page = 1)
            );
          } else if (this.activeForm == 2) {
            return (
              (this.dues.surcon = surcon),
              (this.show_dialog_dues = true),
              (this.dues_page = 1)
            );
          }
        } else {
          this.alert.message =
            "Surveyor with the SURCON NUMBER specified is not found!";
        }
      }, 1000);
    },
    openDialogRequest(item) {
      this.surcon = 0;
      if (item == undefined) {
        return (this.show_dialog_surcon = true);
      } else {
        //alert(JSON.stringify(item))
        this.request.name = item.name;
        this.request.surcon = item.id;

        this.request.pillarSuffix = item.pillarSuffix;
      }
      this.submitted = false;
      return (this.show_dialog_request = true), (this.request_page = 1);
    },
    openDialogDues(item) {
      this.surcon = 0;
      if (item == undefined) {
        return (this.show_dialog_surcon = true);
      } else {
        this.active_surveyor.name = item.name;
        this.dues.surcon = item.surcon;
      }
      this.submitted = false;
      return (this.show_dialog_dues = true), (this.dues_page = 1);
    },
    clearForm: function () {
      this.document = this.emptyDocument;
      this.request = {};
      this.show_dialog_request = false;    
    },
    generateQRCode: function makeCode() {
      var qrData = {
        name: this.request.name,
        receipt_no: this.request.invoice_no,
        plan_no: this.planno,
        date: this.request.request_date,
      };
      var qrcode = new QRCode(document.getElementById("qrcode"), {
        width: 200,
        height: 200,
        colorDark: "black",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      qrcode.makeCode(qrData);
    },
    submitDues: function () {
      const vm = this;
      this.showProgress = true;
      const receipt_no = new Date().getTime();
      this.dues.receipt_no = this.dues.surcon + "-" + receipt_no;

      Api()
        .post("api/nis/annual_dues", this.dues)
        .then((response) => {
          this.submitted = true;
          this.showProgress = false;
          this.alert.alertDialog = true;
          this.alert.snackbar = true;
          this.alert.title = "Members Dues";
          (this.alert.bgcolor = "primary"),
            (this.alert.text = response.data.message);
          this.page = 2;
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.submitted = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    
    submitAccount() {
      const vm = this;
      this.showProgress = true;
      Api()
        .post("api/nis/account_transactions", this.account)
        .then((response) => {
          this.submitted = true;
          this.showProgress = false;
          this.alert.snackbar = true;
          this.alert.alertDialog = true;
          this.alert.title = "NIS Finance";
          this.account = this.account_empty;
          (this.alert.bgcolor = "primary"),
            (this.alert.text = response.data.message);
          this.page = 2;
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    getMembers: function () {
      const vm = this;
      this.showProgress = true;
      Api()
        .get("api/surveyor")
        .then((response) => {
          vm.showProgress = false;
          //console.log(response.data);
          this.$store.commit("setMembers", response.data);
          return (this.members = response.data);
        })
        .catch(function (ex) {
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
    saveQRcode: function () {
      const vm = this;
      this.showProgress = true;
      // get the qr div, then find the canvas element inside it
      var canvas = document.getElementById("qrcode").querySelector("canvas");
      var dataURL = canvas.toDataURL();
      this.qrCodePath = dataURL;
      //alert(dataURL);
      var qr = this.qrSecurity;
      let data = { base64String: dataURL };
      Api()
        .post("api/surveyor", data)
        .then((response) => {
          this.members.unshift(this.member);
          return (
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "blue"),
            (vm.alert.text = response.data)
          );
        })
        .catch(function (ex) {
          if (!ex.response) {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.message)
            );
          } else {
            return (
              (vm.showProgress = false),
              (vm.alert.alertDialog = true),
              (vm.alert.bgcolor = "red"),
              (vm.alert.text = ex.response.data.message)
            );
          }
        });
    },
    Download: function () {
      this.showProgress = true;
      var canvas = document.getElementById("qrcode").querySelector("canvas");
      var dataURL = canvas.toDataURL();
      var link = document.createElement("a");
      link.download = this.document.title + ".png";
      // Construct the uri
      var uri = dataURL;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      // Cleanup the DOM
      document.body.removeChild(link);
      //delete link;
      this.alert.text = "QR Security code was downloaded successfully";
      this.showProgress = false;
    },
    onFileUpload(evt) {
      this.file = evt[0];
      this.createImage(this.file);
    },
    createImage(file) {
      var image = new Image();
      var reader = new FileReader();
      var vm = this;
      reader.onload = (e) => {
        vm.Profile.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function (e) {
      this.Profile.image = "";
    },
    Clear: function () {
      this.Profile = {};
    },
    encriptPass(plainPassword) {
      const a = this.CryptoJS.AES.encrypt(
        plainPassword,
        localStorage.getItem("userName")
      ).toString();
      //alert(a);
      return a;
    },
    decriptPass(encriptedPassword) {
      const a = this.CryptoJS.AES.decrypt(
        encriptedPassword,
        localStorage.getItem("userName")
      ).toString(this.CryptoJS.enc.Utf8);
      //alert(a);
      return a;
    },
    removeConnection(i) {
      this.connections.splice(i, 1);
      const connections = this.connections;
      localStorage.setItem(
        "connections",
        this.encriptPass(JSON.stringify(connections))
      );
    },
  },
  watch: {},
  created() {
    return (
      this.getMembers()
      //this.getAccountCategory(),
      //this.getAccountSubCategory(),
      //this.getAccountType()
    );
  },
};

const arr = (x) => Array.from(x);
const num = (x) => Number(x) || 0;
const str = (x) => String(x);
const isEmpty = (xs) => xs.length === 0;
const take = (n) => (xs) => xs.slice(0, n);
const drop = (n) => (xs) => xs.slice(n);
const reverse = (xs) => xs.slice(0).reverse();
const comp = (f) => (g) => (x) => f(g(x));
const not = (x) => !x;
const chunk = (n) => (xs) =>
  isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

// numToWords :: (Number a, String a) => a -> String
let numToWords = (n) => {
  let a = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];

  let b = [
    "",
    "",
    "and twenty",
    "and thirty",
    "and forty",
    "and fifty",
    "and sixty",
    "and seventy",
    "and eighty",
    "and ninety",
  ];

  let g = [
    "",
    "thousand",
    "million",
    "billion",
    "trillion",
    "quadrillion",
    "quintillion",
    "sextillion",
    "septillion",
    "octillion",
    "nonillion",
  ];

  // this part is really nasty still
  // it might edit this again later to show how Monoids could fix this up
  let makeGroup = ([ones, tens, huns]) => {
    return [
      num(huns) === 0 ? "" : a[huns] + " hundred ",
      num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
      a[tens + ones] || a[ones],
    ].join("");
  };

  let thousand = (group, i) => (group === "" ? group : `${group} ${g[i]}`);

  if (typeof n === "number") return numToWords(String(n));
  else if (n === "0") return "zero";
  else
    return comp(chunk(3))(reverse)(arr(n))
      .map(makeGroup)
      .map(thousand)
      .filter(comp(not)(isEmpty))
      .reverse()
      .join(", ");
};
</script>

<style lang="scss">
.list-db-featureclass {
  margin: 10px;
  max-height: 200px;
  overflow: auto;
}

.outer-wrapper {
  display: table;
  width: 100%;
  height: 95%;
  overflow: auto;
}

.wrap {
  word-wrap: break-word;
}

.inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
  overflow: auto;
}

// .invoice-box {
//   max-width: 800px;
//   margin: auto;
//   padding: 30px;
//   border: 1px solid #eee;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
//   font-size: 14px;
//   line-height: 24px;
//   font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
//   color: #555;
// }

// .invoice-box table {
//   width: 100%;
//   line-height: inherit;
//   text-align: left;
// }

// .invoice-box table td {
//   padding: 5px;
//   vertical-align: top;
// }

// .invoice-box table tr td:nth-child(3) {
//   text-align: right;
// }
// .invoice-box table tr td:nth-child(2) {
//   text-align: left;
// }

// .invoice-box table tr.top table td {
//   padding-bottom: 20px;
// }

// .invoice-box table tr.top table td.title {
//   font-size: 45px;
//   line-height: 45px;
//   color: #333;
// }

// .invoice-box table tr.information table td {
//   padding-bottom: 40px;
// }

// .invoice-box table tr.heading td {
//   background: rgb(237, 240, 236);
//   border-bottom: 1px solid #ddd;
//   font-weight: bold;
//   color: rgb(7, 7, 7);
// }

// .invoice-box table tr.details td {
//   padding-bottom: 20px;
// }

// .invoice-box table tr.item td {
//   border-bottom: 1px solid #eee;
// }

// .invoice-box table tr.item.last td {
//   border-bottom: none;
// }

// .invoice-box table tr.total td:nth-child(2) {
//   border-top: 2px solid #eee;
//   font-weight: bold;
// }

// @media only screen and (max-width: 600px) {
//   .invoice-box table tr.top table td {
//     width: 100%;
//     display: block;
//     text-align: center;
//   }

//   .invoice-box table tr.information table td {
//     width: 100%;
//     display: block;
//     text-align: center;
//   }
// }

// /** RTL **/
// .rtl {
//   direction: rtl;
//   font-family: Tahoma, "Helvetica Neue", "Helvetica", Helvetica, Arial,
//     sans-serif;
// }

// .rtl table {
//   text-align: right;
// }

// .rtl table tr td:nth-child(2) {
//   text-align: left;
// }
</style>
