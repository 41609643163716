<template>
  <div>
    <v-card-text style="padding-top: 10px">
    <div class="text-center" style="padding: 10px; magin-bottom: 10px">
          <v-toolbar-title>Change Your Password</v-toolbar-title>
          <p>
            Supply the following information to change your password. <br/>
            Note that there is currently no mechanism put in place to recorver 
            your password if you forget it.<br/> You are advised to keep your new password in a safe place.</p
          >
        </div>    
      <v-form v-model="valid">
       <v-text-field
          v-model="credential.oldPassword"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-key"
          :type="show1 ? 'text' : 'password'"
          dense
          outlined
          label="Old Password"
          required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.min,
            rules.passUpperCase,
            rules.passLowerCase,
            rules.passNumber,
          ]"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
      
        <v-text-field
          v-model="credential.newPassword"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-key"
          :type="show1 ? 'text' : 'password'"
          dense
          outlined
          label="Password"
          required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[
            rules.required,
            rules.min,
            rules.passUpperCase,
            rules.passLowerCase,
            rules.passNumber,
            rules.passSpecialChar,
          ]"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
          v-model="credential.confirmPassword"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-key"
          :type="show1 ? 'text' : 'password'"
          dense
          outlined
          label="Confirm Password"
          required
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.passwordMatch]"
          name="input-10-1"
          hint="At least 8 characters"
          counter
          @click:append="show1 = !show1"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-btn
        tile
        block
        @click="changePassword()"
        :loading="loading"
        :disabled="!valid"
        class="primary"
        >Change Password</v-btn
      >
    </v-card-actions>
    <v-divider class="ma-2"></v-divider>
    <div class="text-center ma-2">
      <br />
    </div>
    <v-dialog
      v-model="showProgress"
      class="pa-3"
      hide-overlay
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="white">
          Processing your request, please wait...
          <v-progress-linear
            indeterminate
            color="primary"
            class="my-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="alert.snackbar"
      :bottom="alert.y === 'bottom'"
      :color="alert.bgcolor"
      :left="alert.x === 'left'"
      :right="alert.x === 'right'"
      :timeout="alert.timeout"
      :top="alert.y === 'top'"
      >{{ alert.text }}</v-snackbar
    >
    <v-dialog v-model="alert.alertDialog" max-width="290">
      <v-card>
        <div :class="alert.bgcolor">
          <v-card-title class="title">
            <v-icon v-if="alert.bgcolor == 'red'" color="white">cancel</v-icon>
            <v-icon v-else color="white">done</v-icon>
            {{ alert.title }}
          </v-card-title>
        </div>
        <v-card-text>
          <br />
          {{ alert.text }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-show="alert.bgcolor == 'red'"
            color="red darken-1"
            text
            @click="alert.alertDialog = false"
            >Cancel
          </v-btn>
          <v-btn
            v-if="alert.bgcolor == 'red'"
            text
            @click="alert.alertDialog = false"
            >OK</v-btn
          >
          <v-btn
            v-else
            color="green"
            text
            @click="
              showDialog = false;
              alert.alertDialog = false;
            "
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import Api from "../app.settings.network";
export default {
  data: function () {
    return {
      valid: false,
      loading: false,
      disableSignIn: false,
      isLoaded: false,
      country: "",
      city: "",
      credential: {
        oldPassword:"",
        newPassword:"",
        confirmPassword:"",
      },
      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: (v) => /.+@.+/.test(v) || "The email is not valid",
        passUpperCase: (v) =>
          /[A-Z]/.test(v) || "password must have at least one UPPER CASE",
        passLowerCase: (v) =>
          /[a-z]/.test(v) || "password must have at least one LOWER CASE",
        passNumber: (v) =>
          /\d/.test(v) || "password must have at least one NUMBER",
        passSpecialChar: (v) =>
          /\W/.test(v) || "password must have at least one SPECIAL CHARACTER",
        passwordMatch: (v) =>
          v == this.credential. newPassword ||
          "The password and re-type password you entered do not match",
      },
       showProgress: false,
      alert: {
        alertDialog: false,
        bgcolor: "",
        title: "NIS",
        color: "",
        mode: "",
        snackbar: false,
        text: "",
        timeout: 5000,
        x: "right",
        y: "top",
      },
      
    };
  },
  computed: {
    userName: function () {
      return this.$store.getters.getUserName;
    },
  },
  methods: {   
      changePassword: function () {
      const vm = this;
      this.showProgress = true;
      this.credential.email = this.userName;
      Api()
        .post("api/ChangePassword", this.credential)
        .then((response) => {
          this.submitted = true;
          this.alert.text = response.data.message;
          this.showProgress = false;
          this.alert.snackbar = true;
          this.alert.bgcolor = "primary";
           this.alert.alertDialog = true
        })
        .catch(function (ex) {
          return (
            (vm.submitted = false),
            (vm.showProgress = false),
            (vm.showDialog = false),
            (vm.alert.alertDialog = true),
            (vm.alert.bgcolor = "red"),
            (vm.alert.text = ex.response.data.message)
          );
        });
    },
  },
  mounted() {
    
  },
};
</script>

<style lang="scss">
.outer-wrapper {
  display: table;
  width: 100%;
  height: 95%;
  overflow: auto;
}

.inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
  overflow: auto;
}
</style>
