<template>
  <v-row>
    <v-col class="col-lg-12 mt-10">
      <div
        class="text-center"
        style="
          padding: 0px;
          margin-bottom: 0px;
          margin: auto;
          text-align: center;
        ">
        <div class="mt-7">
          <img aspect-ratio="1" width="80" src="../assets/logo.png" />
          <h3>SURCON STATE COMMITTE ON ETHICS</h3>
          <h3>LAGOS STATE BRANCH</h3>        
         <p>Survey Plan and Pillar Number Management System(v1.0)</p>
        </div>
      </div>
      <v-row>
        <v-col class="col-lg-4 col-md-5 col-sm-7" style="margin: auto">
          <v-card-text style="padding-top: 10px">
            <v-form v-model="valid">
              <v-text-field
                v-model="credential.email"
                label="Email"
                placeholder="Enter your email"
                prepend-inner-icon="mdi-email"
                hint="This is the email you used during sign up"
                :rules="[rules.required, rules.emailMatch]"
                outlined
                required
                dense
              ></v-text-field>

              <v-text-field
                v-model="credential.password"
                placeholder="Enter your password"
                prepend-inner-icon="mdi-key"
                :type="show1 ? 'text' : 'password'"
                outlined
                label="Password"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                name="input-10-1"
                hint="At least 8 characters"
                counter
                dense
                @click:append="show1 = !show1"
              ></v-text-field>
              <v-switch
                class="my-n3"
                v-model="credential.rememberMe"
                label="Remember Me"
                dense
              ></v-switch>
            </v-form>
          </v-card-text>
          <v-card-actions class="mx-2">
            <v-btn
              tile
              block
              :loading="loading"
              :disabled="!valid"
              @click="login()"
              color="primary white--text"
              >Login</v-btn
            >
          </v-card-actions>
          <!-- <v-divider class="ma-4"></v-divider> -->
        
        </v-col>
      </v-row>
      <v-dialog v-model="progress.visible" hide-overlay persistent width="300">
        <v-card color="progress" dark>
          <v-card-text>
            {{ progress.message }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="alert.snackbar"
        :bottom="alert.y === 'bottom'"
        :color="alert.bgcolor"
        :left="alert.x === 'left'"
        :right="alert.x === 'right'"
        :timeout="alert.timeout"
        :top="alert.y === 'top'"
      >
        {{ alert.text }}
        <!-- <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>-->
      </v-snackbar>
      <v-dialog v-model="alert.alertDialog" max-width="290">
        <v-card>
          <div :class="alert.bgcolor">
            <v-card-title class="title">
              <v-icon v-if="alert.bgcolor == 'red'" color="white"
                >cancel</v-icon
              >
              <v-icon v-else color="white">card_membership</v-icon>
              {{ alert.title }}
            </v-card-title>
          </div>
          <v-card-text>
            <br />
            {{ alert.text }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-show="alert.bgcolor == 'red'"
              color="red darken-1"
              text
              @click="alert.alertDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              v-if="alert.bgcolor == 'red'"
              text
              @click="
                showDialog = true;
                alert.alertDialog = false;
              "
              >Retry</v-btn
            >
            <v-btn
              v-else
              color="green"
              text
              @click="
                showDialog = false;
                alert.alertDialog = false;
              "
              >Ok</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: function () {
    return {
      country: "",
      city: "",
      valid: false,
      isLoaded: false,
      loading: false,
      credential: { appId:"com.securesurveyplan.app", email: "", password: "", rememberMe: true },
      show1: false,
      password: "Password",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: (v) =>
          (v.includes("@") && v.includes(".")) || "The email is not valid",
        passwordStrength: (v) =>
          (/\d/.test(v) &&
            /[a-z]/.test(v) &&
            /[A-Z]/.test(v) &&
            /\W/.test(v)) ||
          "password must be at least 1 Uppercase, 1 Lowercase, 1 number and 1 Special character",
      },
    };
  },
  computed: {
    loginId() {
      return this.$store.getters.getLoginId;
    },
    progress() {
      return this.$store.getters.getProgressStatus;
    },
    alert_message1() {
      return this.$store.getters.getAlertMessage;
    },
    alert() {
      return this.$store.getters.getAlert;
    },
  },
  methods: {
    googleLogin() {
      this.$store.dispatch("googleLogin");
      // this.$gAuth
      //   .signIn()
      //   .then(GoogleUser => {
      //     //on success do something
      //     console.log("GoogleUser", GoogleUser);
      //     alert(GoogleUser)
      //   })
      //   .catch(ex => {
      //     alert('error : ' + ex)
      //   });
    },
    createAccount: function () {
      if (this.register.email == "") {
        this.alert.bgcolor = "red";
        this.alert.text = "please supply the email field!";
        this.alert.bgcolor = "red";
      } else if (this.register.password == "") {
        this.alert.snackbar = true;
        this.alert.text = "please supply the password field!";
        this.alert.bgcolor = "red";
      } else if (this.register.retypepassword == "") {
        this.alert.snackbar = true;
        this.alert.text = "please supply the retype-password field!";
        this.alert.bgcolor = "red";
      } else if (this.register.password != this.register.confirmpassword) {
        this.alert.snackbar = true;
        this.alert.text = "retype-password is not the same as password!";
        this.alert.bgcolor = "red";
      } else {
        this.$store.commit("setProgressStatus", true);
        this.$store.dispatch("createAccount", this.register);
      }
    },
    login: function () {
      if (!this.valid) {
        return;
      }
      this.loading = true;
      //const payload = { visible: true, message: "signing in, please wait..." };
      //this.$store.commit("setProgressStatus", payload);
      this.$store.dispatch("login", this.credential);
    },
  },
  watch: {
    alert() {
      this.loading = false;
    },
  },
  mounted() {
    // let that = this
    // let checkGauthLoad = setInterval(function(){
    //   that.isLoaded = that.$gAuth.isLoaded()
    //   if(that.isLoaded) clearInterval(checkGauthLoad)
    // }, 1000);
  },
  // created() {
  //   Api2()
  //     .get("https://extreme-ip-lookup.com/json")
  //     .then(response => {
  //       //alert(JSON.stringify(response))
  //         this.country = response.country,
  //         this.city = response.city
  //     }).catch(function(ex) {
  //       //alert(JSON.stringify(ex))
  //     })
  // }
};
</script>

<style lang="scss">
.outer-wrapper {
  display: table;
  width: 100%;
  height: 95%;
  overflow: auto;
}

.inner-wrapper {
  display: table-cell;
  vertical-align: middle;
  padding: 15px;
  overflow: auto;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
