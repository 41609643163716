import Vue from 'vue'
import Vuex from 'vuex'
import authentication from './modules/Authentication'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    members:[],
    date: new Date().toLocaleString(),        
    //new Date().toLocaleDateString();
    //new Date().toLocaleTimeString();
    progress: {visible:false, message:""},
    success: { show: false, message: '', duration: 3000 },
    alert: {
      alertDialog: false,
      bgcolor: '',
      title: 'Database Conection',
      mode: '',
      snackbar: false,
      text: '',
      timeout: 4000,
      x: 'right',
      y: 'top'
    }
  },
  getters: {
    getMembers:state => {
      return state.members
    },
    getAlert: state => {
      return state.alert
    },
    getProgressStatus: state => {
      return state.progress
    }
  },
  mutations: {
    setMembers (state, payload) {
      state.members = payload;
    },
    setAlert (state, alert) {
      state.alert = alert;
    },
    setError (state, message) {
      state.error.message = message
      state.error.show = true
    },
    setProgressStatus: (state, payload) => {
      state.progress = payload
    }
  },
  actions: {},
  modules: {
    authentication,
  }
})
